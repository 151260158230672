<template>
  <div class="form-group"
       :class="[{ 'has-error': errorMessage, 'has-success' : !errorMessage && value }, { 'required-field': required }]"
  >
    <label>{{ label }}</label>
    <div class="input-group date" ref="visionDatePicker">
      <input type='text' class="form-control" :value="value"/>
      <span class="input-group-addon">
               <span class="glyphicon glyphicon-calendar"></span>
          </span>
    </div>
    <span
      v-show="errorMessage"
      class="help-block"
    >
        {{ errorMessage }}
    </span>
  </div>
</template>
<script>
import $ from 'jquery'
import 'eonasdan-bootstrap-datetimepicker'

export default {
  name: 'DateTimePickerType',
  props: {
    value: {},
    config: {type: Object, default: () => ({format: 'YYYY-MM-DD HH:mm'})},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    label: {type: String, default: ''},
    errorMessage: {type: null, required: true}
  },
  data () {
    return {
      picker: null
    }
  },
  mounted () {
    this.picker = $(this.$refs['visionDatePicker'])
    $(this.picker).datetimepicker({...this.config})
    this.picker.on('dp.change', this.handleChange)
    this.picker.on('dp.error', this.handleErrorChange)
    if (this.disabled) {
      this.picker.data('DateTimePicker').disable()
    }
  },
  beforeDestroy () {
    this.picker.off('dp.change', this.handleChange)
    this.picker.off('dp.error', this.handleErrorChange)
    this.picker.data('DateTimePicker').destroy()
    this.picker = null
  },
  methods: {
    handleChange (e) {
      try {
        this.$emit('input', e.date.format(this.config.format))
      } catch (e) {
        this.$emit('input', '')
      }
    },
    handleErrorChange (e) {
      if (!e.oldDate) {
        this.$emit('input', '')
        this.$emit('error', {message: `Proszę wpisać poprawną datę w formacie ${this.config.format}`})
        return
      }
      this.$emit('input', e.oldDate.format(this.config.format))
    }
  }
}
</script>

<style scoped>

</style>
